<template>
  <div class="container">
    <div class="flex flex-wrap md:bg-white md:p-4 lg:p-8 rounded-br-md  rounded-bl-md">
      <Sidebar
        :menu-default-open="true"
        :show-statistics-mobile="true"
        :show-news-mobile="true"
      />

      <section class="pl-6 hidden md:block md:w-3/4">
        <h1 class="text-red font-bold font-serif text-3xl mb-8">
          {{ __('index.logged-in.title', {name: member.first_name}) }}
        </h1>

        <div class="grid grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <Link
            :href="route('frontend.mailings.index')"
            class="shadow-box rounded p-4  flex flex-wrap content-center text-center"
          >
            <span class="block w-full">
              <img
                src="/geldrace/images/emails.png"
                alt=""
                class="mx-auto mb-4"
              >
            </span>

            <span class="font-bold block w-full">
              {{ __('index.logged-in.blocks.emails') }}
            </span>
          </Link>

          <Link
            :href="route('frontend.earn-cashback')"
            class="shadow-box rounded p-4  flex flex-wrap content-center text-center"
          >
            <span class="block w-full">
              <img
                src="/geldrace/images/saldo-spekken.png"
                alt=""
                class="mx-auto mb-4"
              >
            </span>

            <span class="font-bold block w-full">
              {{ __('index.logged-in.blocks.earn-cashback') }}
            </span>
          </Link>

          <Link
            :href="route('frontend.puzzles')"
            class="shadow-box rounded p-4  flex flex-wrap content-center text-center"
          >
            <span class="block w-full">
              <img
                src="/geldrace/images/puzzelen.png"
                alt=""
                class="mx-auto mb-4"
              >
            </span>

            <span class="font-bold block w-full">
              {{ __('index.logged-in.blocks.puzzles') }}
            </span>
          </Link>

          <Link
            :href="route('frontend.webshop-category.index')"
            class="shadow-box rounded p-4  flex flex-wrap content-center text-center"
          >
            <span class="block w-full">
              <img
                src="/geldrace/images/webwinkels.png"
                alt=""
                class="mx-auto mb-4"
              >
            </span>

            <span class="font-bold block w-full">
              {{ __('index.logged-in.blocks.webshops') }}
            </span>
          </Link>
        </div>

        <h2 class="font-bold font-serif text-2xl mb-4">
          {{ __('index.logged-in.top-shops.title') }}
        </h2>

        <div class="grid grid-cols-3 xl:grid-cols-4 gap-6">
          <WebshopBlock
            v-for="(webshop, i) in famousWebshops"
            :key="`webshop-${i}`"
            :webshop="webshop"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import WebshopBlock from '../../Components/Webshop/Block.vue';

export default {
  components: {
    WebshopBlock,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    famousWebshops: {
      type: Object,
      required: true,
    },
  },
};
</script>
